import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccessAppConfig = {
  AUGUST_API_KEY: Scalars['String']['output'];
};

export enum AccessFrequency {
  PERMANENT = 'PERMANENT',
  RECURRING = 'RECURRING',
  TEMPORARY = 'TEMPORARY'
}

export type AccessKey = {
  accessFrequency: AccessFrequency;
  accessKeyId: Scalars['ID']['output'];
  accessKeyUuid: Scalars['ID']['output'];
  accessPointEventsByAccessKeyId: Array<AccessPointEvent>;
  activatesAt?: Maybe<Scalars['DateTime']['output']>;
  activationStatus: AccessKeyActivationStatus;
  appDisplay: AccessKeyAppDisplay;
  appointmentInfo: AppointmentInfo;
  assignedToAnonymousUserId?: Maybe<Scalars['ID']['output']>;
  assignedToUser?: Maybe<User>;
  assignedToUserId?: Maybe<Scalars['ID']['output']>;
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  parentAccessKeyId?: Maybe<Scalars['ID']['output']>;
  property: Property;
  propertyId: Scalars['ID']['output'];
  /** @deprecated Replaced with 'reasonEnum' */
  purpose?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced with 'reasonEnum */
  reason?: Maybe<Scalars['String']['output']>;
  reasonEnum?: Maybe<Option>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  timetable?: Maybe<Array<TimetableRule>>;
  timezone: Scalars['String']['output'];
  type: AccessKeyType;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']['output']>;
};

export enum AccessKeyActivationStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  EXPIRED = 'EXPIRED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  PENDING_DEACTIVATION = 'PENDING_DEACTIVATION',
  REVOKED = 'REVOKED'
}

export type AccessKeyAppDisplay = {
  rowHeading1: Scalars['String']['output'];
  rowHeading2: Scalars['String']['output'];
};

export type AccessKeyInput = {
  activatesAt: Scalars['DateTime']['input'];
  assignedToUserId: Scalars['ID']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  purpose?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  type: AccessKeyType;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type AccessKeySubData = {
  new?: Maybe<AccessKey>;
  old?: Maybe<AccessKey>;
};

export type AccessKeySubEvent = {
  data?: Maybe<AccessKeySubData>;
  op?: Maybe<OperationType>;
};

export enum AccessKeyType {
  GUEST = 'GUEST',
  RESIDENT = 'RESIDENT',
  STAFF = 'STAFF',
  TESTER = 'TESTER',
  VENDOR = 'VENDOR'
}

export type AccessPoint = {
  accessPointId: Scalars['ID']['output'];
  accessTimeout: Scalars['Int']['output'];
  beacons: Array<Beacon>;
  deprecatedAccessPointId: Scalars['ID']['output'];
  /** @deprecated Replaced by 'name' after v1.13.0 */
  friendlyName: Scalars['String']['output'];
  /** @deprecated No longer being used */
  hubsOffline?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['ID']['output']>;
  mapLocation?: Maybe<Scalars['JSON']['output']>;
  maxOccupancy?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  property: Property;
  propertyId: Scalars['ID']['output'];
  proximityRestriction: Scalars['Boolean']['output'];
  readers: Array<Reader>;
  relays: Array<Relay>;
  /** @deprecated Replaced by 'type' */
  reservationOnly: Scalars['Boolean']['output'];
  spaceshipModeEnabled: Scalars['Boolean']['output'];
  /** @deprecated Replaced by 'type' */
  staffAccessOnly: Scalars['Boolean']['output'];
  timetable?: Maybe<Array<TimetableRule>>;
  timezone: Scalars['String']['output'];
  type: AccessPointType;
};

export type AccessPointEvent = {
  accessPointEventId: Scalars['ID']['output'];
  accessPointId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  removedByUserId?: Maybe<Scalars['ID']['output']>;
  startsAt: Scalars['DateTime']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AccessPointEventsInput = {
  accessPointId: Scalars['ID']['input'];
  endsAt: Scalars['DateTime']['input'];
  startsAt: Scalars['DateTime']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AccessPointPreferencesInput = {
  accessPointId: Scalars['ID']['input'];
  favorited?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type AccessPointRelay = {
  accessPointId: Scalars['ID']['output'];
  accessPointRelayId: Scalars['ID']['output'];
  relayId: Scalars['ID']['output'];
};

export type AccessPointRelayInput = {
  accessPointId: Scalars['ID']['input'];
  relayId: Scalars['ID']['input'];
};

export type AccessPointSettings = {
  accessPoint: AccessPoint;
  accessPointId: Scalars['ID']['output'];
  accessPointSettingsId: Scalars['ID']['output'];
  favorited: Scalars['Boolean']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['ID']['output'];
};

export type AccessPointSubData = {
  new?: Maybe<AccessPoint>;
  old?: Maybe<AccessPoint>;
};

export type AccessPointSubEvent = {
  data?: Maybe<AccessPointSubData>;
  op?: Maybe<OperationType>;
};

export enum AccessPointType {
  AMENITY = 'AMENITY',
  COMMON_AREA = 'COMMON_AREA',
  RESERVATION_ONLY = 'RESERVATION_ONLY',
  RESTRICTED_BLACKLIST = 'RESTRICTED_BLACKLIST',
  RESTRICTED_WHITELIST = 'RESTRICTED_WHITELIST'
}

export type AccessPointUpdateInput = {
  accessTimeout: Scalars['Int']['input'];
};

export type AccessPointsInput = {
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Alert = {
  resolution: Scalars['String']['output'];
  severity: AlertSeverity;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum AlertSeverity {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MODERATE = 'MODERATE'
}

export type AlertTotal = {
  alert: Alert;
  total: Scalars['Int']['output'];
};

export type Announcement = {
  announcementId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  message: Scalars['String']['output'];
  preview: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AnnouncementSubData = {
  new?: Maybe<Announcement>;
  old?: Maybe<Announcement>;
};

export type AnnouncementSubEvent = {
  data: AnnouncementSubData;
  op: OperationType;
};

export type AppointmentInfo = {
  bookedUnits: Array<BookedUnit>;
};

export type ApproveAccessRequestInput = {
  accessPointId: Scalars['ID']['input'];
  anonymousUserId: Scalars['ID']['input'];
};

export type AugustLock = {
  Bridge?: Maybe<AugustLockBridge>;
  HouseID: Scalars['ID']['output'];
  HouseName: Scalars['String']['output'];
  LockID: Scalars['ID']['output'];
  LockName: Scalars['String']['output'];
  LockStatus: AugustLockStatus;
  SerialNumber: Scalars['String']['output'];
  batteryInfo: AugustLockBatteryInfo;
  chipFirmware: AugustLockFirmwareInfo;
  currentFirmwareVersion?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Replaced with chipFirmware */
  firmware?: Maybe<AugustLockFirmware>;
  hostLockInfo?: Maybe<Scalars['JSON']['output']>;
  macAddress: Scalars['String']['output'];
  pinCodes: Array<AugustPinCode>;
  skuNumber?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  usersList: Array<AugustLockUser>;
};

export type AugustLockBatteryInfo = {
  infoUpdatedDate?: Maybe<Scalars['DateTime']['output']>;
  lastChangeDate?: Maybe<Scalars['DateTime']['output']>;
  level?: Maybe<Scalars['Float']['output']>;
  warningState: Scalars['String']['output'];
};

export type AugustLockBridge = {
  _id: Scalars['ID']['output'];
  deviceModel: Scalars['String']['output'];
  firmwareVersion: Scalars['String']['output'];
  operative: Scalars['Boolean']['output'];
  status: AugustLockBridgeStatus;
};

export type AugustLockBridgeStatus = {
  current?: Maybe<Scalars['String']['output']>;
  lastOffline?: Maybe<Scalars['DateTime']['output']>;
  lastOnline?: Maybe<Scalars['DateTime']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type AugustLockFirmware = {
  updateInfo: Array<AugustLockFirmwareUpdate>;
};

export type AugustLockFirmwareInfo = {
  currentVersion?: Maybe<Scalars['String']['output']>;
  desiredVersion?: Maybe<Scalars['String']['output']>;
};

export type AugustLockFirmwareUpdate = {
  version: Scalars['String']['output'];
};

export type AugustLockStatus = {
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
};

export type AugustLockUser = {
  FirstName: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
  UserType: Scalars['String']['output'];
  identifiers: Array<Scalars['String']['output']>;
  pinCodeStatus?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type AugustPinCode = {
  accessEndTime?: Maybe<Scalars['DateTime']['output']>;
  accessStartTime?: Maybe<Scalars['DateTime']['output']>;
  accessType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  loadedDate?: Maybe<Scalars['DateTime']['output']>;
  lockID: Scalars['ID']['output'];
  partnerUserID?: Maybe<Scalars['ID']['output']>;
  slot: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  unverified: Scalars['Boolean']['output'];
  userID: Scalars['ID']['output'];
};

export enum AuthMethod {
  AUGUST_EMAIL_AND_PASSWORD = 'AUGUST_EMAIL_AND_PASSWORD',
  AUGUST_OAUTH = 'AUGUST_OAUTH',
  AUGUST_PHONE_NUMBER_AND_EMAIL = 'AUGUST_PHONE_NUMBER_AND_EMAIL',
  AUGUST_PHONE_NUMBER_AND_PASSWORD = 'AUGUST_PHONE_NUMBER_AND_PASSWORD',
  EMAIL = 'EMAIL',
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PHONE_NUMBER_AND_EMAIL = 'PHONE_NUMBER_AND_EMAIL',
  PHONE_NUMBER_AND_PASSWORD = 'PHONE_NUMBER_AND_PASSWORD',
  SINGLE_SIGN_ON = 'SINGLE_SIGN_ON'
}

export type Beacon = {
  accessPointId: Scalars['ID']['output'];
  beaconId: Scalars['ID']['output'];
  /** @deprecated Replaced by 'major */
  beaconMajor?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Replaced by 'minor */
  beaconMinor?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Replaced by 'uuid */
  beaconUuid?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by 'name' */
  friendlyName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  major: Scalars['Int']['output'];
  minor: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer needed */
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type BeaconInsertOrUpdateInput = {
  accessPointId: Scalars['ID']['input'];
  beaconMajor: Scalars['Int']['input'];
  beaconMinor: Scalars['Int']['input'];
  beaconUuid: Scalars['ID']['input'];
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  macAddress: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type BeaconPassword = {
  currentPassword: Scalars['String']['output'];
  desiredPassword?: Maybe<Scalars['String']['output']>;
  macAddress: Scalars['String']['output'];
};

export type BookedUnit = {
  pinCode?: Maybe<Scalars['String']['output']>;
  unitId: Scalars['ID']['output'];
};

export type Client = {
  /** @deprecated No longer used by the access app */
  clientId: Scalars['ID']['output'];
  /** @deprecated No longer used by the access app */
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated No longer used by the access app */
  displayName: Scalars['String']['output'];
  /** @deprecated No longer used by the access app */
  uniqueName: Scalars['String']['output'];
  /** @deprecated No longer used by the access app */
  updatedAt: Scalars['DateTime']['output'];
};

export type ConfirmBeaconPasswordRotatedInput = {
  macAddress: Scalars['String']['input'];
  rotatedPassword: Scalars['String']['input'];
};

export type CreateGuestPassInput = {
  nickname: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
};

export type CreateRelayLogInput = {
  accessKeyId?: InputMaybe<Scalars['ID']['input']>;
  accessPointId?: InputMaybe<Scalars['ID']['input']>;
  readerInput?: InputMaybe<Scalars['String']['input']>;
  relayNumber: Scalars['Int']['input'];
  unlockMethod?: InputMaybe<UnlockMethod>;
  unlockResult?: InputMaybe<UnlockResult>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSsoSessionInput = {
  organizationId: Scalars['ID']['input'];
  passcode: Scalars['String']['input'];
  sessionType: SessionType;
  userId: Scalars['ID']['input'];
};

export type CreateTicketInput = {
  smartLockId: Scalars['ID']['input'];
  type: TicketType;
};

export type Credential = {
  assignedToUserId: Scalars['ID']['output'];
  credentialId: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  type: CredentialType;
};

export type CredentialInput = {
  identifier: Scalars['String']['input'];
  type: CredentialType;
};

export enum CredentialType {
  FOB = 'FOB',
  MOBILE = 'MOBILE'
}

export type DateRangeInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type DetermineAuthMethodInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userIdentifier?: InputMaybe<UserIdentifierInput>;
};

export type EditGuestInput = {
  accessFrequency: AccessFrequency;
  accessKeyId?: InputMaybe<Scalars['String']['input']>;
  activatesAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  guestAccessKeyId: Scalars['String']['input'];
  nickname: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  timetableRules?: InputMaybe<Array<TimetableRuleInput>>;
};

export type EditUnitKeyInput = {
  accessKeyId: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export enum FailurePlan {
  FAIL_SAFE = 'FAIL_SAFE',
  FAIL_SECURE = 'FAIL_SECURE'
}

export type Favorite = {
  accessPointId: Scalars['ID']['output'];
  favoriteId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type FavoriteInput = {
  accessPointId: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type FavoritesInput = {
  accessKeyId?: InputMaybe<Scalars['ID']['input']>;
  accessPointId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};

export type GeneratePropertyPinCodeInput = {
  assignedToUserId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};

export enum GpioMode {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export type GrantGlobalUserRoleInput = {
  assignedToUserId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GrantScopedUserRolesInput = {
  activatesAt: Scalars['DateTime']['input'];
  assignedToUserId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  parentUserRoleId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
  scopedIds: Array<Scalars['ID']['input']>;
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GrantStaffUnitKeyInput = {
  assignedToUserId: Scalars['ID']['input'];
  expiresAt: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  scopedUnitId: Scalars['ID']['input'];
};

export type Hub = {
  activationStatus: HubActivationStatus;
  channels?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deviceType: HubDeviceType;
  /** @deprecated Replaced by 'name' */
  friendlyName?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  hubId: Scalars['ID']['output'];
  /** @deprecated Replaced by 'hubId' */
  hubUuid: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  /** @deprecated Status no longer tracked in database */
  isOfflineSince?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Status no longer tracked in database */
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  monitoring: HubMonitoring;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Status no longer tracked in database */
  offlineSince?: Maybe<Scalars['DateTime']['output']>;
  port?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Replaced by 'channels' */
  type?: Maybe<HubType>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum HubActivationStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION'
}

export enum HubDeviceType {
  ETHOS_EDGE_READER = 'ETHOS_EDGE_READER',
  ETHOS_EDGE_READER_NC = 'ETHOS_EDGE_READER_NC',
  ETHOS_EDGE_READER_NO = 'ETHOS_EDGE_READER_NO',
  ETHOS_SECURE_SIDE_DEVICE = 'ETHOS_SECURE_SIDE_DEVICE',
  ETHOS_SECURE_SIDE_DEVICE_NC = 'ETHOS_SECURE_SIDE_DEVICE_NC',
  ETHOS_SECURE_SIDE_DEVICE_NO = 'ETHOS_SECURE_SIDE_DEVICE_NO',
  RASPBERRY_PI = 'RASPBERRY_PI'
}

export type HubFilterInput = {
  accessPointIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  hubIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  relayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type HubInput = {
  channels?: InputMaybe<Scalars['Int']['input']>;
  deviceType?: InputMaybe<HubDeviceType>;
  location?: InputMaybe<Scalars['String']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type HubMessage = {
  hubId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  messageGroupId?: Maybe<Scalars['ID']['output']>;
  messageId: Scalars['ID']['output'];
  payload: Scalars['JSON']['output'];
  timestamp: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type HubMessageInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  messageGroupId?: InputMaybe<Scalars['ID']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  type: Scalars['String']['input'];
};

export type HubMonitoring = {
  lastDisconnectionAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HubResponse = {
  hub: Hub;
  hubId: Scalars['ID']['output'];
  response?: Maybe<HubMessage>;
  responseTime?: Maybe<Scalars['Int']['output']>;
};

export enum HubType {
  EIGHT_CHANNEL = 'EIGHT_CHANNEL',
  FOUR_CHANNEL = 'FOUR_CHANNEL',
  SIXTEEN_CHANNEL = 'SIXTEEN_CHANNEL'
}

export type Image = {
  cloudId: Scalars['ID']['output'];
  imageId: Scalars['ID']['output'];
  uploadedByUserId?: Maybe<Scalars['ID']['output']>;
};

export type ImageTransformOptions = {
  crop?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['String']['input']>;
};

export enum InputDevice {
  AMAZON_KEY = 'AMAZON_KEY',
  DPS = 'DPS',
  INTERCOM = 'INTERCOM',
  OTHER = 'OTHER',
  REX = 'REX'
}

export enum InvitationTemplate {
  GENERIC_WELCOME = 'GENERIC_WELCOME',
  UNIT_GUEST = 'UNIT_GUEST',
  UNREGISTERED_USER = 'UNREGISTERED_USER'
}

export type InviteGuestInput = {
  accessFrequency: AccessFrequency;
  accessKeyId: Scalars['String']['input'];
  activatesAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  nickname: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  timetableRules?: InputMaybe<Array<TimetableRuleInput>>;
};

export type LoginVerificationOutput = {
  session?: Maybe<SessionOutput>;
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export enum MessageMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export type MessageSentFromHubInput = {
  hubIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Mutation = {
  acceptEula: User;
  approveAccessRequest?: Maybe<AccessKey>;
  cancelAccessPointReservation: AccessPointEvent;
  claimGuestPass: AccessKey;
  confirmAugustLockSync: SmartLock;
  confirmBeaconPasswordRotated: BeaconPassword;
  createGuestPass: AccessKey;
  createRelayLog: RelayLog;
  createSsoSession: SessionOutput;
  createTicket: Ticket;
  deleteSmartLock: SmartLock;
  editGuest: AccessKey;
  editUnitKey: AccessKey;
  ensureUserExists: User;
  feedback: Scalars['Boolean']['output'];
  generateAnonymousAccessToken: Scalars['String']['output'];
  generatePropertyPinCode: Scalars['Boolean']['output'];
  /** @deprecated Replaced with grantStaffUnitKey */
  grantAccessKeys: Array<AccessKey>;
  grantGlobalUserRole: UserRole;
  grantScopedUserRoles: Array<UserRole>;
  grantStaffUnitKey: AccessKey;
  initiateVirtualDirectoryRequest: Scalars['String']['output'];
  insertOrUpdateBeacon: Beacon;
  insertOrUpdateRelay: Relay;
  inviteGuest: AccessKey;
  markAnnouncementsAsRead: User;
  provisionCredential: Credential;
  provisionSmartLock: SmartLock;
  removeAccessPointRelay: AccessPointRelay;
  removeBeacon: Beacon;
  removeHome: Scalars['Boolean']['output'];
  renewSession: SessionOutput;
  reserveAccessPoint: AccessPointEvent;
  revokeGuest: Scalars['Boolean']['output'];
  /** @deprecated Replaced with new send mutations */
  sendEmailCode: Scalars['ID']['output'];
  sendInvitation: Scalars['Boolean']['output'];
  sendLoginCode: Scalars['String']['output'];
  sendMessageToHubs: Array<HubMessage>;
  sendNewIdentifierCode: Scalars['String']['output'];
  /** @deprecated Replaced with new send mutations */
  sendSmsCode: Scalars['ID']['output'];
  /** @deprecated Replaced with setPassword */
  setAugustPassword: User;
  setPassword: User;
  signOut?: Maybe<Scalars['ID']['output']>;
  sortFavorites: Array<Favorite>;
  syncResidentsByProperty: Scalars['Boolean']['output'];
  syncResidentsByUnit: Scalars['Boolean']['output'];
  syncSmartLockAccess: SmartLock;
  syncUnitsByProperty: Scalars['Boolean']['output'];
  /** @deprecated Replaced with 'sortFavorites' after v1.12.0 */
  toggleAccessPointFavorite: Array<AccessPointSettings>;
  toggleFavorite: Array<Favorite>;
  unlockAccessPoint: AccessPoint;
  unlockRelays: Scalars['Boolean']['output'];
  updateAccessPoint: AccessPoint;
  /** @deprecated Replaced with 'toggleFavorite' after v1.12.0 */
  updateFavoritesOrder: Array<AccessPointSettings>;
  updateHub: Hub;
  updateSmartLock: SmartLock;
  /** @deprecated Replaced with August lock webhook handler */
  updateTicketStatus: Ticket;
  updateUserProfile: User;
  uploadImage: Image;
  /** @deprecated Replaced with new verify mutations */
  verifyEmailCode: SessionOutput;
  verifyLoginCode: LoginVerificationOutput;
  verifyNewIdentifierCode: User;
  verifyPassword: LoginVerificationOutput;
  /** @deprecated Replaced with new verify mutations */
  verifySmsCode: SessionOutput;
};


export type Mutation_approveAccessRequestArgs = {
  input: ApproveAccessRequestInput;
};


export type Mutation_cancelAccessPointReservationArgs = {
  accessPointEventId: Scalars['ID']['input'];
};


export type Mutation_claimGuestPassArgs = {
  accessKeyUuid: Scalars['ID']['input'];
};


export type Mutation_confirmAugustLockSyncArgs = {
  augustLockId: Scalars['ID']['input'];
};


export type Mutation_confirmBeaconPasswordRotatedArgs = {
  input: ConfirmBeaconPasswordRotatedInput;
};


export type Mutation_createGuestPassArgs = {
  input: CreateGuestPassInput;
};


export type Mutation_createRelayLogArgs = {
  input: CreateRelayLogInput;
};


export type Mutation_createSsoSessionArgs = {
  input: CreateSsoSessionInput;
};


export type Mutation_createTicketArgs = {
  input: CreateTicketInput;
};


export type Mutation_deleteSmartLockArgs = {
  smartLockId: Scalars['ID']['input'];
};


export type Mutation_editGuestArgs = {
  editGuestInput: EditGuestInput;
};


export type Mutation_editUnitKeyArgs = {
  input: EditUnitKeyInput;
};


export type Mutation_ensureUserExistsArgs = {
  input: UserInput;
};


export type Mutation_feedbackArgs = {
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type Mutation_generatePropertyPinCodeArgs = {
  input: GeneratePropertyPinCodeInput;
};


export type Mutation_grantAccessKeysArgs = {
  inputs: Array<AccessKeyInput>;
};


export type Mutation_grantGlobalUserRoleArgs = {
  input: GrantGlobalUserRoleInput;
};


export type Mutation_grantScopedUserRolesArgs = {
  input: GrantScopedUserRolesInput;
};


export type Mutation_grantStaffUnitKeyArgs = {
  input: GrantStaffUnitKeyInput;
};


export type Mutation_initiateVirtualDirectoryRequestArgs = {
  input: VirtualDirectoryRequestInput;
};


export type Mutation_insertOrUpdateBeaconArgs = {
  beaconId?: InputMaybe<Scalars['ID']['input']>;
  input: BeaconInsertOrUpdateInput;
};


export type Mutation_insertOrUpdateRelayArgs = {
  input: RelayInsertOrUpdateInput;
  relayId?: InputMaybe<Scalars['ID']['input']>;
};


export type Mutation_inviteGuestArgs = {
  inviteGuestInput: InviteGuestInput;
};


export type Mutation_provisionCredentialArgs = {
  input: CredentialInput;
};


export type Mutation_provisionSmartLockArgs = {
  input: ProvisionSmartLockInput;
};


export type Mutation_removeAccessPointRelayArgs = {
  input: AccessPointRelayInput;
};


export type Mutation_removeBeaconArgs = {
  beaconId: Scalars['ID']['input'];
};


export type Mutation_removeHomeArgs = {
  accessKeyId: Scalars['ID']['input'];
};


export type Mutation_renewSessionArgs = {
  chirpRefreshToken: Scalars['String']['input'];
  input?: InputMaybe<SessionScopeInput>;
};


export type Mutation_reserveAccessPointArgs = {
  input: ReserveAccessPointInput;
};


export type Mutation_revokeGuestArgs = {
  guestAccessKeyId: Scalars['ID']['input'];
};


export type Mutation_sendEmailCodeArgs = {
  input: SendEmailCodeInput;
};


export type Mutation_sendInvitationArgs = {
  input: SendInvitationInput;
};


export type Mutation_sendLoginCodeArgs = {
  input: SendLoginCodeInput;
};


export type Mutation_sendMessageToHubsArgs = {
  input: SendMessageToHubsInput;
};


export type Mutation_sendNewIdentifierCodeArgs = {
  input: SendNewIdentifierCodeInput;
};


export type Mutation_sendSmsCodeArgs = {
  input: SendSmsCodeInput;
};


export type Mutation_setAugustPasswordArgs = {
  password: Scalars['String']['input'];
};


export type Mutation_setPasswordArgs = {
  password: Scalars['String']['input'];
};


export type Mutation_signOutArgs = {
  chirpRefreshToken: Scalars['String']['input'];
};


export type Mutation_sortFavoritesArgs = {
  inputs: Array<FavoriteInput>;
};


export type Mutation_syncResidentsByPropertyArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Mutation_syncResidentsByUnitArgs = {
  unitId: Scalars['ID']['input'];
};


export type Mutation_syncSmartLockAccessArgs = {
  smartLockId: Scalars['ID']['input'];
};


export type Mutation_syncUnitsByPropertyArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Mutation_toggleAccessPointFavoriteArgs = {
  input: AccessPointPreferencesInput;
};


export type Mutation_toggleFavoriteArgs = {
  favorited: Scalars['Boolean']['input'];
  input: FavoriteInput;
};


export type Mutation_unlockAccessPointArgs = {
  input: UnlockAccessPointInput;
};


export type Mutation_unlockRelaysArgs = {
  input: UnlockRelaysInput;
};


export type Mutation_updateAccessPointArgs = {
  accessPointId: Scalars['ID']['input'];
  input: AccessPointUpdateInput;
};


export type Mutation_updateFavoritesOrderArgs = {
  favorites: Array<InputMaybe<FavoritesInput>>;
};


export type Mutation_updateHubArgs = {
  hubId: Scalars['ID']['input'];
  input: HubInput;
};


export type Mutation_updateSmartLockArgs = {
  input: UpdateSmartLockInput;
  smartLockId: Scalars['ID']['input'];
};


export type Mutation_updateTicketStatusArgs = {
  status: TicketStatus;
  ticketId: Scalars['ID']['input'];
};


export type Mutation_updateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type Mutation_uploadImageArgs = {
  file: Scalars['Upload']['input'];
};


export type Mutation_verifyEmailCodeArgs = {
  input: VerifyEmailCodeInput;
};


export type Mutation_verifyLoginCodeArgs = {
  input: VerifyLoginCodeInput;
};


export type Mutation_verifyNewIdentifierCodeArgs = {
  input: VerifyNewIdentifierCodeInput;
};


export type Mutation_verifyPasswordArgs = {
  input: VerifyPasswordInput;
};


export type Mutation_verifySmsCodeArgs = {
  input: VerifySmsCodeInput;
};

export enum OperatingSystem {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export enum OperationType {
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE'
}

export type Option = {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PaginationInput = {
  pageLimit?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  totalLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type PinCode = {
  assignedToUserId?: Maybe<Scalars['ID']['output']>;
  code: Scalars['String']['output'];
  pinCodeId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  userRoleId?: Maybe<Scalars['ID']['output']>;
};

export type PopularAccessPoint = {
  accessPointId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  totalLogs: Scalars['Int']['output'];
};

export type PopularAccessPointsInput = {
  activityStartDate: Scalars['DateTime']['input'];
  limit: Scalars['Int']['input'];
  propertyId: Scalars['ID']['input'];
};

export type PopularAccessPointsOutput = {
  accessPoints: Array<PopularAccessPoint>;
  grandTotalLogs: Scalars['Int']['output'];
};

export type Property = {
  accessPoints: Array<AccessPoint>;
  address?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer used by the access app */
  client: Client;
  /** @deprecated Replaced by 'organizationId' */
  clientId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  featureFlags: Array<Scalars['String']['output']>;
  hubs: Array<Hub>;
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  propertyMap?: Maybe<PropertyMap>;
  supportPhoneNumber?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  units: Array<Unit>;
  updatedAt: Scalars['DateTime']['output'];
  virtualDirectoryPhoneNumber?: Maybe<Scalars['String']['output']>;
  virtualDirectoryStaffHours?: Maybe<Array<TimetableRule>>;
};


export type Property_unitsArgs = {
  hasLock?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyMap = {
  externalAssetId: Scalars['String']['output'];
  externalFloors: Scalars['JSON']['output'];
  externalMapId: Scalars['String']['output'];
  externalUnits: Scalars['JSON']['output'];
  propertyId: Scalars['ID']['output'];
  propertyMapId: Scalars['ID']['output'];
  published: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export enum PropertyMapType {
  ELEVATION = 'ELEVATION',
  FLOOR_PLATE = 'FLOOR_PLATE',
  OBLIQUE = 'OBLIQUE',
  STACK = 'STACK'
}

export type PropertySmartLockStats = {
  totalSmartLocks: Scalars['Int']['output'];
  totalUnitsWithSmartLock: Scalars['Int']['output'];
  totalUnitsWithoutSmartLock: Scalars['Int']['output'];
};

export type PropertySmartLocksReportOutput = {
  alertTotals: Array<AlertTotal>;
  property: Property;
  smartLocks: Array<SmartLock>;
};

export type ProvisionSmartLockInput = {
  name: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type Query = {
  accessAppConfig: AccessAppConfig;
  accessPointEvents: Array<AccessPointEvent>;
  accessPointPublic: AccessPoint;
  accessPoints: Array<AccessPoint>;
  auditResidentsByProperty: Array<ResidentAudit>;
  auditResidentsByUnit: Array<ResidentAudit>;
  augustLocks: Array<AugustLock>;
  beaconPasswordByMacAddress: BeaconPassword;
  currentUser: User;
  determineAuthMethod: AuthMethod;
  guestPass: AccessKey;
  hubByPk: Hub;
  latestAccessAppVersion: Scalars['String']['output'];
  popularAccessPoints: PopularAccessPointsOutput;
  property: Property;
  propertySmartLockStats: PropertySmartLockStats;
  propertySmartLocksReport: PropertySmartLocksReportOutput;
  relayLogs: RelayLogsOutput;
  /** @deprecated Replaced with 'userRoleReasons' */
  roleAssignmentPurposes: Array<Option>;
  smartLockByPk: SmartLock;
  smartLockBySerialNumber: SmartLockBySerialNumberOutput;
  smartLockLogs: Array<SmartLockLog>;
  smartLockLogsPaginated: SmartLockLogsOutput;
  unassignedHubs: Array<Hub>;
  unit: Unit;
  userActivity: Array<UserActivityOutput>;
  userRoleReasons: Array<Option>;
  userStatistics: UserStatisticsOutput;
  verifyReaderAccess: ReaderAccess;
  virtualDirectoryAudit: VirtualDirectoryAuditOutput;
  virtualDirectoryPublic: VirtualDirectoryPublicOutput;
};


export type Query_accessPointEventsArgs = {
  input: AccessPointEventsInput;
};


export type Query_accessPointPublicArgs = {
  accessPointId: Scalars['ID']['input'];
};


export type Query_accessPointsArgs = {
  input?: InputMaybe<AccessPointsInput>;
};


export type Query_auditResidentsByPropertyArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Query_auditResidentsByUnitArgs = {
  unitId: Scalars['ID']['input'];
};


export type Query_augustLocksArgs = {
  augustLockIds: Array<Scalars['ID']['input']>;
};


export type Query_beaconPasswordByMacAddressArgs = {
  macAddress: Scalars['String']['input'];
};


export type Query_determineAuthMethodArgs = {
  input: DetermineAuthMethodInput;
};


export type Query_guestPassArgs = {
  accessKeyUuid: Scalars['ID']['input'];
};


export type Query_hubByPkArgs = {
  hubId: Scalars['ID']['input'];
};


export type Query_latestAccessAppVersionArgs = {
  operatingSystem: OperatingSystem;
};


export type Query_popularAccessPointsArgs = {
  input: PopularAccessPointsInput;
};


export type Query_propertyArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Query_propertySmartLockStatsArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Query_propertySmartLocksReportArgs = {
  propertyId: Scalars['ID']['input'];
};


export type Query_relayLogsArgs = {
  input: RelayLogsInput;
};


export type Query_smartLockByPkArgs = {
  smartLockId: Scalars['ID']['input'];
};


export type Query_smartLockBySerialNumberArgs = {
  serialNumber: Scalars['ID']['input'];
};


export type Query_smartLockLogsArgs = {
  input: SmartLockLogsInput;
};


export type Query_smartLockLogsPaginatedArgs = {
  input: SmartLockLogsPaginatedInput;
};


export type Query_unitArgs = {
  unitId: Scalars['ID']['input'];
};


export type Query_userActivityArgs = {
  input: UserActivityInput;
};


export type Query_userStatisticsArgs = {
  input: UserStatisticsInput;
};


export type Query_verifyReaderAccessArgs = {
  input: VerifyReaderAccessInput;
};


export type Query_virtualDirectoryAuditArgs = {
  input: VirtualDirectoryAuditInput;
};


export type Query_virtualDirectoryPublicArgs = {
  input: VirtualDirectoryPublicInput;
};

export type Reader = {
  address?: Maybe<Scalars['String']['output']>;
  childHubId?: Maybe<Scalars['ID']['output']>;
  readerId: Scalars['ID']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type ReaderAccess = {
  accessKeyId?: Maybe<Scalars['ID']['output']>;
  accessPointId?: Maybe<Scalars['ID']['output']>;
  accessTimeout?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  readerInput: Scalars['String']['output'];
  relayNumber?: Maybe<Scalars['Int']['output']>;
  unlockResult: UnlockResult;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum ReaderType {
  KEYPAD = 'KEYPAD',
  LONG_RANGE = 'LONG_RANGE',
  NON_KEYPAD = 'NON_KEYPAD'
}

export type Relay = {
  childHubId?: Maybe<Scalars['ID']['output']>;
  failurePlan: FailurePlan;
  gpioMode: GpioMode;
  hubId: Scalars['ID']['output'];
  inputDevice?: Maybe<InputDevice>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  readerAddress?: Maybe<Scalars['String']['output']>;
  readerSerialNumber?: Maybe<Scalars['String']['output']>;
  readerType?: Maybe<ReaderType>;
  relayId: Scalars['ID']['output'];
  unlockOnInternetFailure: Scalars['Boolean']['output'];
};

export type RelayInsertOrUpdateInput = {
  accessPointId: Scalars['ID']['input'];
  childHubId?: InputMaybe<Scalars['ID']['input']>;
  failurePlan?: InputMaybe<FailurePlan>;
  gpioMode?: InputMaybe<GpioMode>;
  hubId: Scalars['ID']['input'];
  inputDevice?: InputMaybe<InputDevice>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  readerAddress?: InputMaybe<Scalars['String']['input']>;
  readerSerialNumber?: InputMaybe<Scalars['String']['input']>;
  readerType?: InputMaybe<ReaderType>;
  unlockOnInternetFailure?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RelayLog = {
  /** @deprecated Replaced with userRoleId */
  accessKeyId?: Maybe<Scalars['ID']['output']>;
  accessPoint?: Maybe<AccessPoint>;
  accessPointId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  inputRelay?: Maybe<Relay>;
  /** @deprecated Supports backward compatibility (old Log model) */
  logId: Scalars['ID']['output'];
  property: Property;
  propertyId: Scalars['ID']['output'];
  /** @deprecated Replaced with restricted.readerInput */
  readerInput?: Maybe<Scalars['String']['output']>;
  relay: Relay;
  relayId: Scalars['ID']['output'];
  relayLogId: Scalars['ID']['output'];
  restricted?: Maybe<RelayLogRestricted>;
  unlockMethod?: Maybe<UnlockMethod>;
  unlockResult: UnlockResult;
  /** @deprecated Replaced with restricted.userId */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type RelayLogFiltersInput = {
  accessPointId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<DateRangeInput>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  relayId?: InputMaybe<Scalars['ID']['input']>;
  relayLogId?: InputMaybe<Scalars['ID']['input']>;
  searchTerms?: InputMaybe<Array<Scalars['String']['input']>>;
  unlockMethod?: InputMaybe<Array<UnlockMethod>>;
  unlockResult?: InputMaybe<Array<UnlockResult>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RelayLogRestricted = {
  readerInput?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserLimited>;
  userId?: Maybe<Scalars['ID']['output']>;
  userRole?: Maybe<UserRole>;
  userRoleId?: Maybe<Scalars['ID']['output']>;
};

export type RelayLogsInput = {
  filters?: InputMaybe<RelayLogFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RelayLogsOutput = {
  list: Array<RelayLog>;
  total: Scalars['Int']['output'];
};

export type RelaysUnlockedEvent = {
  /** @deprecated Replaced by 'userRoleId' */
  accessKeyId?: Maybe<Scalars['ID']['output']>;
  accessPoint?: Maybe<AccessPoint>;
  hubId: Scalars['ID']['output'];
  /** @deprecated Replaced by 'hubId' */
  hubUuid: Scalars['ID']['output'];
  unlockMethod?: Maybe<UnlockMethod>;
  unlockedRelays: Array<UnlockedRelay>;
  userId?: Maybe<Scalars['ID']['output']>;
  userRoleId?: Maybe<Scalars['ID']['output']>;
};

export type ReserveAccessPointInput = {
  accessPointId: Scalars['ID']['input'];
  endsAt: Scalars['DateTime']['input'];
  startsAt: Scalars['DateTime']['input'];
};

export type ResidentAudit = {
  dataScrubRequirements: Array<Scalars['String']['output']>;
  dataWarnings: Array<Scalars['String']['output']>;
  eligibility: Scalars['String']['output'];
  ineligibilityReasons: Array<Scalars['String']['output']>;
  residentSource: ResidentSource;
  unit: Unit;
  userRole?: Maybe<UserRole>;
};

export type ResidentSource = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  moveInDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer used for resident audit/sync */
  moveOutDate?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  residentSourceId: Scalars['ID']['output'];
};

export enum ScheduleRuleState {
  CONTROLLED = 'CONTROLLED',
  LOCKDOWN = 'LOCKDOWN',
  UNLOCKED = 'UNLOCKED'
}

export enum ScheduleRuleType {
  ALLOWANCE = 'ALLOWANCE',
  RESTRICTION = 'RESTRICTION'
}

export type SendEmailCodeInput = {
  codeType?: InputMaybe<VerificationCodeType>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  sessionType: SessionType;
};

export type SendInvitationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  invitationTemplate: InvitationTemplate;
  messageMethod?: InputMaybe<MessageMethod>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userRoleId?: InputMaybe<Scalars['ID']['input']>;
};

export type SendLoginCodeInput = {
  sessionType: SessionType;
  userIdentifier: UserIdentifierInput;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

export type SendMessageToHubsAsyncInput = {
  hubFilter: HubFilterInput;
  hubMessage: HubMessageInput;
  responseTimeout?: InputMaybe<Scalars['Int']['input']>;
};

export type SendMessageToHubsInput = {
  hubIds: Array<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  messageGroupId?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  type: Scalars['String']['input'];
};

export type SendNewIdentifierCodeInput = {
  userIdentifier: UserIdentifierInput;
};

export type SendSmsCodeInput = {
  codeType?: InputMaybe<VerificationCodeType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  sessionType: SessionType;
};

export type SessionOutput = {
  augustAccessToken?: Maybe<Scalars['String']['output']>;
  chirpAccessToken: Scalars['String']['output'];
  chirpRefreshToken?: Maybe<Scalars['String']['output']>;
  chirpUser: User;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SessionScopeInput = {
  permissionScope: Scalars['String']['input'];
  scopedId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SessionType {
  ACCESS_APP = 'ACCESS_APP',
  ADMIN_PANEL = 'ADMIN_PANEL'
}

export type SmartLock = {
  alerts: Array<Alert>;
  augustLock?: Maybe<AugustLock>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  currentUserPermissions: Array<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  openTickets: Array<Ticket>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  smartLockId: Scalars['ID']['output'];
  sourceId: Scalars['ID']['output'];
  unit: Unit;
  unitId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SmartLockBySerialNumberOutput = {
  smartLock?: Maybe<SmartLock>;
  sourceId: Scalars['ID']['output'];
};

export type SmartLockLog = {
  /** @deprecated Replaced with actingUserRoleId */
  accessKeyId?: Maybe<Scalars['ID']['output']>;
  actingUser?: Maybe<UserLimited>;
  actingUserId?: Maybe<Scalars['ID']['output']>;
  actingUserRoleId?: Maybe<Scalars['ID']['output']>;
  affectedUserId?: Maybe<Scalars['ID']['output']>;
  category: SmartLockLogCategory;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Supports backward compatibility (old Log model) */
  logId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  severity: SmartLockLogSeverity;
  smartLock: SmartLock;
  smartLockId: Scalars['ID']['output'];
  smartLockLogId: Scalars['ID']['output'];
  sourceId?: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  type: SmartLockLogType;
};

export enum SmartLockLogCategory {
  GENERAL = 'GENERAL',
  GUEST_MANAGEMENT = 'GUEST_MANAGEMENT',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  WORK_ORDER = 'WORK_ORDER'
}

export type SmartLockLogFiltersInput = {
  actingUserId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<DateRangeInput>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  searchTerms?: InputMaybe<Array<Scalars['String']['input']>>;
  smartLockId?: InputMaybe<Scalars['ID']['input']>;
  smartLockLogId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Array<SmartLockLogType>>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SmartLockLogSeverity {
  ALERT = 'ALERT',
  CRITICAL = 'CRITICAL',
  DEBUG = 'DEBUG',
  EMERGENCY = 'EMERGENCY',
  ERROR = 'ERROR',
  INFO = 'INFO',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING'
}

export enum SmartLockLogType {
  LOCK_MANUAL = 'LOCK_MANUAL',
  LOCK_VIA_APP = 'LOCK_VIA_APP',
  LOCK_VIA_AUTO_LOCK = 'LOCK_VIA_AUTO_LOCK',
  LOCK_VIA_KEYPAD = 'LOCK_VIA_KEYPAD',
  PIN_CODE_MANAGEMENT_CREATE = 'PIN_CODE_MANAGEMENT_CREATE',
  PIN_CODE_MANAGEMENT_DELETE = 'PIN_CODE_MANAGEMENT_DELETE',
  UNLOCK_MANUAL = 'UNLOCK_MANUAL',
  UNLOCK_VIA_APP = 'UNLOCK_VIA_APP',
  UNLOCK_VIA_KEYPAD = 'UNLOCK_VIA_KEYPAD',
  USER_MANAGEMENT_ADD = 'USER_MANAGEMENT_ADD',
  USER_MANAGEMENT_REMOVE = 'USER_MANAGEMENT_REMOVE'
}

export type SmartLockLogsInput = {
  smartLockId: Scalars['ID']['input'];
};

export type SmartLockLogsOutput = {
  list: Array<SmartLockLog>;
  total: Scalars['Int']['output'];
};

export type SmartLockLogsPaginatedInput = {
  filters?: InputMaybe<SmartLockLogFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type Subscription = {
  accessKeyChanged: AccessKeySubEvent;
  accessPointChanged: AccessPointSubEvent;
  announcementChanged: AnnouncementSubEvent;
  anonymousUserRoles: Array<AccessKey>;
  messageSentFromHub: HubMessage;
  relaysUnlocked: RelaysUnlockedEvent;
  sendMessageToHubs: Array<HubResponse>;
  sessionRevoked: Scalars['ID']['output'];
  unitChanged: UnitSubEvent;
  verificationCodes: VerificationCode;
};


export type Subscription_messageSentFromHubArgs = {
  input: MessageSentFromHubInput;
};


export type Subscription_sendMessageToHubsArgs = {
  input: SendMessageToHubsAsyncInput;
};


export type Subscription_verificationCodesArgs = {
  userId: Scalars['ID']['input'];
};

export type Ticket = {
  assignedToUserId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  propertyId: Scalars['ID']['output'];
  smartLockId?: Maybe<Scalars['ID']['output']>;
  solvedAt?: Maybe<Scalars['DateTime']['output']>;
  solvedByUserId?: Maybe<Scalars['ID']['output']>;
  status: TicketStatus;
  ticketId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  unitId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TicketStatus {
  PENDING = 'PENDING',
  SOLVED = 'SOLVED',
  UNSOLVED = 'UNSOLVED'
}

export enum TicketType {
  SMART_LOCK_LOW_BATTERY = 'SMART_LOCK_LOW_BATTERY',
  TURNOVER_LOCK_RESET = 'TURNOVER_LOCK_RESET'
}

export type TimetableRule = {
  days: Array<Scalars['Int']['output']>;
  end: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  start: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<TimetableRuleState>;
  type: TimetableRuleType;
};

export type TimetableRuleInput = {
  days: Array<Scalars['Int']['input']>;
  end: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<TimetableRuleState>;
  type: TimetableRuleType;
};

export enum TimetableRuleState {
  CONTROLLED = 'CONTROLLED',
  LOCKDOWN = 'LOCKDOWN',
  UNLOCKED = 'UNLOCKED'
}

export enum TimetableRuleType {
  ALLOWANCE = 'ALLOWANCE',
  RESTRICTION = 'RESTRICTION'
}

export type Unit = {
  /** @deprecated We now support multiple locks per unit */
  augustLockId?: Maybe<Scalars['ID']['output']>;
  building?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  /** @deprecated Removed in v1.8.0 */
  image?: Maybe<Image>;
  /** @deprecated Removed in v1.8.0 */
  imageId?: Maybe<Scalars['ID']['output']>;
  occupancy: UnitOccupancy;
  openTickets: Array<Ticket>;
  property: Property;
  propertyId: Scalars['ID']['output'];
  smartLocks: Array<SmartLock>;
  /** @deprecated Replaced by 'smartLocks' */
  smartLocksByUnitId: Array<SmartLock>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  totalSmartLocks: Scalars['Int']['output'];
  type?: Maybe<UnitType>;
  unitId: Scalars['ID']['output'];
  unitNumber: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum UnitOccupancy {
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT'
}

export type UnitSubData = {
  new?: Maybe<Unit>;
  old?: Maybe<Unit>;
};

export type UnitSubEvent = {
  data?: Maybe<UnitSubData>;
  op?: Maybe<OperationType>;
};

export enum UnitType {
  RESIDENTIAL = 'RESIDENTIAL',
  STAFF = 'STAFF'
}

export type UnlockAccessPointInput = {
  accessPointId: Scalars['ID']['input'];
  unlockMethod: UnlockMethod;
};

export enum UnlockMethod {
  ACCESS_APP_FAVORITES = 'ACCESS_APP_FAVORITES',
  ACCESS_APP_NEARBY = 'ACCESS_APP_NEARBY',
  ACCESS_APP_SEARCH = 'ACCESS_APP_SEARCH',
  ACCESS_WEB = 'ACCESS_WEB',
  ADMIN_PANEL = 'ADMIN_PANEL',
  CREDENTIAL_MOBILE = 'CREDENTIAL_MOBILE',
  CREDENTIAL_PHYSICAL = 'CREDENTIAL_PHYSICAL',
  CREDENTIAL_UNKNOWN = 'CREDENTIAL_UNKNOWN',
  FOB = 'FOB',
  INPUT_DEVICE = 'INPUT_DEVICE',
  NFC = 'NFC',
  PIN_CODE = 'PIN_CODE',
  QR_CODE = 'QR_CODE',
  SIRI_SHORTCUT = 'SIRI_SHORTCUT',
  SMART_BUILDING_PORTAL = 'SMART_BUILDING_PORTAL',
  SPACESHIP_MODE = 'SPACESHIP_MODE',
  WIDGET_FAVORITES = 'WIDGET_FAVORITES',
  WIDGET_NEARBY = 'WIDGET_NEARBY'
}

export type UnlockRelaysInput = {
  hubId: Scalars['ID']['input'];
  positions?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum UnlockResult {
  ACCESS_DENIED = 'ACCESS_DENIED',
  ACCESS_GRANTED = 'ACCESS_GRANTED'
}

export type UnlockedRelay = {
  position: Scalars['Int']['output'];
  relayId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Replaced by 'position' in v1.10.0 */
  relayNumber: Scalars['Int']['output'];
};

export type UpdateSmartLockInput = {
  name: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
  unitId: Scalars['ID']['input'];
};

export type UpdateUserProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  virtualDirectoryEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  acceptedEulaAt?: Maybe<Scalars['DateTime']['output']>;
  announcements: Array<Announcement>;
  appDisplay: UserAppDisplay;
  assignedAccessKeys: Array<AccessKey>;
  assignedPinCodes: Array<PinCode>;
  augustSubUserId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Replaced with augustSubUserId */
  augustUserId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  favoritesByUserId: Array<AccessPointSettings>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  guestsAccessKeys: Array<AccessKey>;
  /** @deprecated Replaced with hasPassword */
  hasAugustPassword: Scalars['Boolean']['output'];
  hasPassword: Scalars['Boolean']['output'];
  /** @deprecated Removed in v1.9.1 */
  image?: Maybe<Image>;
  /** @deprecated Removed in v1.9.1 */
  imageId?: Maybe<Scalars['ID']['output']>;
  isMockUser: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastReadAnnouncementsAt?: Maybe<Scalars['DateTime']['output']>;
  mobileCredentials: Array<Credential>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
  userRoles: Array<UserRole>;
  /** @deprecated Replaced with userId conversion to uuid */
  userUuid: Scalars['ID']['output'];
  virtualDirectoryEnabled: Scalars['Boolean']['output'];
};

export type UserActivityInput = {
  endDate: Scalars['DateTime']['input'];
  interval: UserActivityInterval;
  propertyId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export enum UserActivityInterval {
  DAY = 'DAY',
  HOUR = 'HOUR'
}

export type UserActivityOutput = {
  date: Scalars['String']['output'];
  gatewayAccess: Scalars['Int']['output'];
  homeAccess: Scalars['Int']['output'];
};

export type UserAppDisplay = {
  canInviteGuests: Scalars['Boolean']['output'];
  ingressOnly: Scalars['Boolean']['output'];
  totalPropertyAccess: Scalars['Int']['output'];
};

export type UserIdentifier = {
  type: UserIdentifierType;
  value: Scalars['String']['output'];
};

export type UserIdentifierInput = {
  type: UserIdentifierType;
  value: Scalars['String']['input'];
};

export enum UserIdentifierType {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER'
}

export type UserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type UserLimited = {
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type UserRole = {
  assignedToUser?: Maybe<User>;
  assignedToUserId?: Maybe<Scalars['ID']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['ID']['output'];
  /** @deprecated Replaced by 'scopedOrganizationId */
  scopedClientId?: Maybe<Scalars['ID']['output']>;
  scopedOrganizationId?: Maybe<Scalars['ID']['output']>;
  scopedPropertyId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Replaced by 'roleId' */
  type?: Maybe<UserRoleType>;
  /** @deprecated Replaced by 'assignedToUserId' */
  userId?: Maybe<Scalars['ID']['output']>;
  userRoleId: Scalars['ID']['output'];
};

export enum UserRoleStatus {
  ACTIVATED = 'ACTIVATED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  REVOKED = 'REVOKED'
}

export enum UserRoleType {
  ADMIN = 'ADMIN',
  CONTACT_CENTER_AGENT = 'CONTACT_CENTER_AGENT',
  CONTACT_CENTER_SUPERVISOR = 'CONTACT_CENTER_SUPERVISOR',
  INTEGRATOR = 'INTEGRATOR',
  MAINTENANCE = 'MAINTENANCE',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  PROPERTY_SUPPORT = 'PROPERTY_SUPPORT'
}

export type UserStatisticsInput = {
  endDate: Scalars['DateTime']['input'];
  propertyId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type UserStatisticsOutput = {
  totalActivatedUsers: Scalars['Int']['output'];
  totalActiveUsers: Scalars['Int']['output'];
  totalEligibleUsers: Scalars['Int']['output'];
};

export type VerificationCode = {
  attempts: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
  userIdentifier: UserIdentifier;
  verificationCodeId: Scalars['ID']['output'];
};

export enum VerificationCodeType {
  AUGUST_2FA = 'AUGUST_2FA',
  AUGUST_NEW_FACTOR = 'AUGUST_NEW_FACTOR'
}

export type VerifyEmailCodeInput = {
  code: Scalars['String']['input'];
  codeType?: InputMaybe<VerificationCodeType>;
  email: Scalars['String']['input'];
  sessionType: SessionType;
};

export type VerifyLoginCodeInput = {
  code: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  verificationToken: Scalars['String']['input'];
};

export type VerifyNewIdentifierCodeInput = {
  code: Scalars['String']['input'];
  userIdentifier: UserIdentifierInput;
};

export type VerifyPasswordInput = {
  password: Scalars['String']['input'];
  sessionType: SessionType;
  userIdentifier: UserIdentifierInput;
};

export type VerifyReaderAccessInput = {
  readerIdentifier: Scalars['String']['input'];
  readerInput: Scalars['String']['input'];
};

export type VerifySmsCodeInput = {
  code: Scalars['String']['input'];
  codeType?: InputMaybe<VerificationCodeType>;
  phoneNumber: Scalars['String']['input'];
  sessionType: SessionType;
};

export type VirtualDirectoryAuditInput = {
  propertyId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type VirtualDirectoryAuditOutput = {
  listedUsers: Array<VirtualDirectoryUser>;
  property: Property;
  totalListedUsers: Scalars['Int']['output'];
  totalUnlistedUsers: Scalars['Int']['output'];
  unitsDisplayed: Scalars['Boolean']['output'];
  unlistedUsers: Array<VirtualDirectoryUser>;
};

export type VirtualDirectoryPublicInput = {
  accessPointId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type VirtualDirectoryPublicOutput = {
  listedUsers: Array<VirtualDirectoryUser>;
  property: Property;
  unitsDisplayed: Scalars['Boolean']['output'];
};

export type VirtualDirectoryRequestInput = {
  accessPointId: Scalars['ID']['input'];
  contactUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type VirtualDirectoryUser = {
  unitDisplayName?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['ID']['output']>;
  unlistedReasons: Array<Scalars['String']['output']>;
  userDisplayName: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type PropertyFragment = { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null };

export type AccessPointFragment = { accessPointId: string, deprecatedAccessPointId: string, propertyId: string, name: string, type: AccessPointType, accessTimeout: number, mapLocation?: any | null, timetable?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null, property: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, image?: { imageId: string, cloudId: string } | null };

export type TimetableRuleFragment = { days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null };

export type GuestPassFragment = { accessKeyId: string, activatesAt?: string | null, expiresAt?: string | null, assignedToAnonymousUserId?: string | null, userRoleId: string, status: AccessKeyActivationStatus, scopedPropertyId: string, scopedProperty: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, image?: { imageId: string, cloudId: string } | null, propertyMap?: { propertyMapId: string, externalMapId: string, propertyId: string, type: string, externalAssetId: string, externalUnits: any, externalFloors: any } | null, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, appointmentInfo: { bookedUnits: Array<{ unitId: string, pinCode?: string | null }> } };

export type PropertyMapFragment = { propertyMapId: string, externalMapId: string, propertyId: string, type: string, externalAssetId: string, externalUnits: any, externalFloors: any };

export type generateAnonymousAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type generateAnonymousAccessTokenMutation = { accessToken: string };

export type initiateVirtualDirectoryRequestMutationVariables = Exact<{
  input: VirtualDirectoryRequestInput;
}>;


export type initiateVirtualDirectoryRequestMutation = { initiateVirtualDirectoryRequest: string };

export type claimGuestPassMutationVariables = Exact<{
  userRoleId: Scalars['ID']['input'];
}>;


export type claimGuestPassMutation = { claimedGuestPass: { accessKeyId: string, activatesAt?: string | null, expiresAt?: string | null, assignedToAnonymousUserId?: string | null, userRoleId: string, status: AccessKeyActivationStatus, scopedPropertyId: string, scopedProperty: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, image?: { imageId: string, cloudId: string } | null, propertyMap?: { propertyMapId: string, externalMapId: string, propertyId: string, type: string, externalAssetId: string, externalUnits: any, externalFloors: any } | null, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, appointmentInfo: { bookedUnits: Array<{ unitId: string, pinCode?: string | null }> } } };

export type unlockAccessPointMutationVariables = Exact<{
  accessPointId: Scalars['ID']['input'];
}>;


export type unlockAccessPointMutation = { unlockAccessPoint: { accessPointId: string } };

export type getAccessPointQueryVariables = Exact<{
  accessPointId: Scalars['ID']['input'];
}>;


export type getAccessPointQuery = { accessPoint: { accessPointId: string, deprecatedAccessPointId: string, propertyId: string, name: string, type: AccessPointType, accessTimeout: number, mapLocation?: any | null, timetable?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null, property: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, image?: { imageId: string, cloudId: string } | null } };

export type getGuestPassQueryVariables = Exact<{
  userRoleId: Scalars['ID']['input'];
}>;


export type getGuestPassQuery = { guestPass: { accessKeyId: string, activatesAt?: string | null, expiresAt?: string | null, assignedToAnonymousUserId?: string | null, userRoleId: string, status: AccessKeyActivationStatus, scopedPropertyId: string, scopedProperty: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, image?: { imageId: string, cloudId: string } | null, propertyMap?: { propertyMapId: string, externalMapId: string, propertyId: string, type: string, externalAssetId: string, externalUnits: any, externalFloors: any } | null, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, appointmentInfo: { bookedUnits: Array<{ unitId: string, pinCode?: string | null }> } } };

export type getVirtualDirectoryQueryVariables = Exact<{
  input: VirtualDirectoryPublicInput;
}>;


export type getVirtualDirectoryQuery = { virtualDirectoryPublic: { unitsDisplayed: boolean, property: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, listedUsers: Array<{ userId: string, userDisplayName: string, unitDisplayName?: string | null }> } };

export type anonymousUserRolesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type anonymousUserRolesSubscription = { anonymousUserRoles: Array<{ accessKeyId: string, activatesAt?: string | null, expiresAt?: string | null, assignedToAnonymousUserId?: string | null, userRoleId: string, status: AccessKeyActivationStatus, scopedPropertyId: string, scopedProperty: { propertyId: string, name: string, timezone: string, address?: string | null, supportPhoneNumber?: string | null, virtualDirectoryPhoneNumber?: string | null, featureFlags: Array<string>, image?: { imageId: string, cloudId: string } | null, propertyMap?: { propertyMapId: string, externalMapId: string, propertyId: string, type: string, externalAssetId: string, externalUnits: any, externalFloors: any } | null, virtualDirectoryStaffHours?: Array<{ days: Array<number>, start: string, end: string, type: TimetableRuleType, state?: TimetableRuleState | null, startDate?: string | null, endDate?: string | null }> | null }, appointmentInfo: { bookedUnits: Array<{ unitId: string, pinCode?: string | null }> } }> };

export const TimetableRuleFragmentDoc = gql`
    fragment TimetableRuleFragment on TimetableRule {
  days
  start
  end
  type
  state
  startDate
  endDate
}
    `;
export const PropertyFragmentDoc = gql`
    fragment PropertyFragment on Property {
  propertyId
  name
  timezone
  address
  supportPhoneNumber
  virtualDirectoryPhoneNumber
  virtualDirectoryStaffHours {
    ...TimetableRuleFragment
  }
  featureFlags
}
    ${TimetableRuleFragmentDoc}`;
export const AccessPointFragmentDoc = gql`
    fragment AccessPointFragment on AccessPoint {
  accessPointId
  deprecatedAccessPointId
  propertyId
  name
  type
  accessTimeout
  timetable {
    ...TimetableRuleFragment
  }
  mapLocation
  property {
    ...PropertyFragment
  }
  image {
    imageId
    cloudId
  }
}
    ${TimetableRuleFragmentDoc}
${PropertyFragmentDoc}`;
export const PropertyMapFragmentDoc = gql`
    fragment PropertyMapFragment on PropertyMap {
  propertyMapId
  externalMapId
  propertyId
  type
  externalAssetId
  externalUnits
  externalFloors
}
    `;
export const GuestPassFragmentDoc = gql`
    fragment GuestPassFragment on AccessKey {
  accessKeyId
  userRoleId: accessKeyId
  status: activationStatus
  scopedPropertyId: propertyId
  activatesAt
  expiresAt
  assignedToAnonymousUserId
  scopedProperty: property {
    ...PropertyFragment
    image {
      imageId
      cloudId
    }
    propertyMap {
      ...PropertyMapFragment
    }
  }
  appointmentInfo {
    bookedUnits {
      unitId
      pinCode
    }
  }
}
    ${PropertyFragmentDoc}
${PropertyMapFragmentDoc}`;
export const generateAnonymousAccessTokenDocument = gql`
    mutation generateAnonymousAccessToken {
  accessToken: generateAnonymousAccessToken
}
    `;
export type generateAnonymousAccessTokenMutationFn = ApolloReactCommon.MutationFunction<generateAnonymousAccessTokenMutation, generateAnonymousAccessTokenMutationVariables>;
export function usegenerateAnonymousAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<generateAnonymousAccessTokenMutation, generateAnonymousAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<generateAnonymousAccessTokenMutation, generateAnonymousAccessTokenMutationVariables>(generateAnonymousAccessTokenDocument, options);
      }
export type generateAnonymousAccessTokenMutationHookResult = ReturnType<typeof usegenerateAnonymousAccessTokenMutation>;
export type generateAnonymousAccessTokenMutationResult = ApolloReactCommon.MutationResult<generateAnonymousAccessTokenMutation>;
export type generateAnonymousAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<generateAnonymousAccessTokenMutation, generateAnonymousAccessTokenMutationVariables>;
export const initiateVirtualDirectoryRequestDocument = gql`
    mutation initiateVirtualDirectoryRequest($input: VirtualDirectoryRequestInput!) {
  initiateVirtualDirectoryRequest(input: $input)
}
    `;
export type initiateVirtualDirectoryRequestMutationFn = ApolloReactCommon.MutationFunction<initiateVirtualDirectoryRequestMutation, initiateVirtualDirectoryRequestMutationVariables>;
export function useinitiateVirtualDirectoryRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<initiateVirtualDirectoryRequestMutation, initiateVirtualDirectoryRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<initiateVirtualDirectoryRequestMutation, initiateVirtualDirectoryRequestMutationVariables>(initiateVirtualDirectoryRequestDocument, options);
      }
export type initiateVirtualDirectoryRequestMutationHookResult = ReturnType<typeof useinitiateVirtualDirectoryRequestMutation>;
export type initiateVirtualDirectoryRequestMutationResult = ApolloReactCommon.MutationResult<initiateVirtualDirectoryRequestMutation>;
export type initiateVirtualDirectoryRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<initiateVirtualDirectoryRequestMutation, initiateVirtualDirectoryRequestMutationVariables>;
export const claimGuestPassDocument = gql`
    mutation claimGuestPass($userRoleId: ID!) {
  claimedGuestPass: claimGuestPass(accessKeyUuid: $userRoleId) {
    ...GuestPassFragment
  }
}
    ${GuestPassFragmentDoc}`;
export type claimGuestPassMutationFn = ApolloReactCommon.MutationFunction<claimGuestPassMutation, claimGuestPassMutationVariables>;
export function useclaimGuestPassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<claimGuestPassMutation, claimGuestPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<claimGuestPassMutation, claimGuestPassMutationVariables>(claimGuestPassDocument, options);
      }
export type claimGuestPassMutationHookResult = ReturnType<typeof useclaimGuestPassMutation>;
export type claimGuestPassMutationResult = ApolloReactCommon.MutationResult<claimGuestPassMutation>;
export type claimGuestPassMutationOptions = ApolloReactCommon.BaseMutationOptions<claimGuestPassMutation, claimGuestPassMutationVariables>;
export const unlockAccessPointDocument = gql`
    mutation unlockAccessPoint($accessPointId: ID!) {
  unlockAccessPoint(
    input: {accessPointId: $accessPointId, unlockMethod: ACCESS_WEB}
  ) {
    accessPointId
  }
}
    `;
export type unlockAccessPointMutationFn = ApolloReactCommon.MutationFunction<unlockAccessPointMutation, unlockAccessPointMutationVariables>;
export function useunlockAccessPointMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<unlockAccessPointMutation, unlockAccessPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<unlockAccessPointMutation, unlockAccessPointMutationVariables>(unlockAccessPointDocument, options);
      }
export type unlockAccessPointMutationHookResult = ReturnType<typeof useunlockAccessPointMutation>;
export type unlockAccessPointMutationResult = ApolloReactCommon.MutationResult<unlockAccessPointMutation>;
export type unlockAccessPointMutationOptions = ApolloReactCommon.BaseMutationOptions<unlockAccessPointMutation, unlockAccessPointMutationVariables>;
export const getAccessPointDocument = gql`
    query getAccessPoint($accessPointId: ID!) {
  accessPoint: accessPointPublic(accessPointId: $accessPointId) {
    ...AccessPointFragment
  }
}
    ${AccessPointFragmentDoc}`;
export function usegetAccessPointQuery(baseOptions: ApolloReactHooks.QueryHookOptions<getAccessPointQuery, getAccessPointQueryVariables> & ({ variables: getAccessPointQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<getAccessPointQuery, getAccessPointQueryVariables>(getAccessPointDocument, options);
      }
export function usegetAccessPointLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<getAccessPointQuery, getAccessPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<getAccessPointQuery, getAccessPointQueryVariables>(getAccessPointDocument, options);
        }
export function usegetAccessPointSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<getAccessPointQuery, getAccessPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<getAccessPointQuery, getAccessPointQueryVariables>(getAccessPointDocument, options);
        }
export type getAccessPointQueryHookResult = ReturnType<typeof usegetAccessPointQuery>;
export type getAccessPointLazyQueryHookResult = ReturnType<typeof usegetAccessPointLazyQuery>;
export type getAccessPointSuspenseQueryHookResult = ReturnType<typeof usegetAccessPointSuspenseQuery>;
export type getAccessPointQueryResult = ApolloReactCommon.QueryResult<getAccessPointQuery, getAccessPointQueryVariables>;
export const getGuestPassDocument = gql`
    query getGuestPass($userRoleId: ID!) {
  guestPass: guestPass(accessKeyUuid: $userRoleId) {
    ...GuestPassFragment
  }
}
    ${GuestPassFragmentDoc}`;
export function usegetGuestPassQuery(baseOptions: ApolloReactHooks.QueryHookOptions<getGuestPassQuery, getGuestPassQueryVariables> & ({ variables: getGuestPassQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<getGuestPassQuery, getGuestPassQueryVariables>(getGuestPassDocument, options);
      }
export function usegetGuestPassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<getGuestPassQuery, getGuestPassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<getGuestPassQuery, getGuestPassQueryVariables>(getGuestPassDocument, options);
        }
export function usegetGuestPassSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<getGuestPassQuery, getGuestPassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<getGuestPassQuery, getGuestPassQueryVariables>(getGuestPassDocument, options);
        }
export type getGuestPassQueryHookResult = ReturnType<typeof usegetGuestPassQuery>;
export type getGuestPassLazyQueryHookResult = ReturnType<typeof usegetGuestPassLazyQuery>;
export type getGuestPassSuspenseQueryHookResult = ReturnType<typeof usegetGuestPassSuspenseQuery>;
export type getGuestPassQueryResult = ApolloReactCommon.QueryResult<getGuestPassQuery, getGuestPassQueryVariables>;
export const getVirtualDirectoryDocument = gql`
    query getVirtualDirectory($input: VirtualDirectoryPublicInput!) {
  virtualDirectoryPublic(input: $input) {
    property {
      ...PropertyFragment
    }
    unitsDisplayed
    listedUsers {
      userId
      userDisplayName
      unitDisplayName
    }
  }
}
    ${PropertyFragmentDoc}`;
export function usegetVirtualDirectoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables> & ({ variables: getVirtualDirectoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>(getVirtualDirectoryDocument, options);
      }
export function usegetVirtualDirectoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>(getVirtualDirectoryDocument, options);
        }
export function usegetVirtualDirectorySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>(getVirtualDirectoryDocument, options);
        }
export type getVirtualDirectoryQueryHookResult = ReturnType<typeof usegetVirtualDirectoryQuery>;
export type getVirtualDirectoryLazyQueryHookResult = ReturnType<typeof usegetVirtualDirectoryLazyQuery>;
export type getVirtualDirectorySuspenseQueryHookResult = ReturnType<typeof usegetVirtualDirectorySuspenseQuery>;
export type getVirtualDirectoryQueryResult = ApolloReactCommon.QueryResult<getVirtualDirectoryQuery, getVirtualDirectoryQueryVariables>;
export const anonymousUserRolesDocument = gql`
    subscription anonymousUserRoles {
  anonymousUserRoles {
    ...GuestPassFragment
  }
}
    ${GuestPassFragmentDoc}`;
export function useanonymousUserRolesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<anonymousUserRolesSubscription, anonymousUserRolesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<anonymousUserRolesSubscription, anonymousUserRolesSubscriptionVariables>(anonymousUserRolesDocument, options);
      }
export type anonymousUserRolesSubscriptionHookResult = ReturnType<typeof useanonymousUserRolesSubscription>;
export type anonymousUserRolesSubscriptionResult = ApolloReactCommon.SubscriptionResult<anonymousUserRolesSubscription>;